import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "w-11/12 h-9 my-auto overflow-x-auto whitespace-nowrap" }
const _hoisted_4 = {
  key: 0,
  class: "block h-9 leading-8"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "w-1/12 my-auto" }
const _hoisted_7 = {
  key: 0,
  class: "absolute max-h-64 min-w-full z-50 border rounded-md border-gray-300 overflow-y-scroll bg-white top-full right-0"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.isExpanded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute w-full h-full z-40 top-0 left-0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.isExpanded = false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col relative h-10 w-full md:mt-2", {
        'bg-gray-200': _ctx.computedValues.disabled,
        'bg-white': _ctx.computedValues.disabled,
        'cursor-pointer': !_ctx.computedValues.disabled,
      }])
    }, [
      _createElementVNode("div", {
        id: _ctx.elementData.id,
        class: _normalizeClass(["multi_select_container bg-white flex border rounded w-full h-10 px-4", {
          'text-white': !_ctx.computedValues.emptyValue,
          'text-gray-6': _ctx.computedValues.emptyValue,
        }]),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.methods.expandActionList && _ctx.methods.expandActionList(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.computedValues.emptyValue)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.elementData?.placeholder ?? "general.select_placeholder")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedValues.selectedLabels, (label, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "multi_select_values inline-block text-white text-sm mr-2 px-2 pb-1 my-auto rounded bg-primary-blue cursor-default",
              key: `${_ctx.elementData.id}_value_${index}_${new Date().getTime()}`,
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createTextVNode(_toDisplayString(label) + " ", 1),
              _createElementVNode("span", {
                class: "text-tiny icon icon-cross cursor-pointer",
                onClick: _withModifiers(($event: any) => (_ctx.methods.removeValue(index)), ["stop"])
              }, null, 8, _hoisted_5)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["block my-auto ml-2 text-gray-6 text-2xl leading-none icon", {
              'icon-drop': !_ctx.state.isExpanded,
              'icon-fold': _ctx.state.isExpanded,
            }]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.methods.expandActionList && _ctx.methods.expandActionList(...args)), ["stop"]))
          }, null, 2)
        ])
      ], 10, _hoisted_2),
      (_ctx.state.isExpanded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedValues.availableOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "px-2 py-3 hover:bg-gray-200",
                onClick: ($event: any) => (_ctx.methods.addValue(option)),
                key: `${_ctx.elementData.id}_option_${index}_${new Date().getTime()}`
              }, _toDisplayString(option.label), 9, _hoisted_8))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}