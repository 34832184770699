import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex relative h-12 w-full md:pt-2" }
const _hoisted_2 = ["type", "max", "step", "id", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "text-black absolute right-4 top-1.5 md:pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.elementData.type,
      min: "0",
      max: _ctx.elementData?.maxValue,
      step: _ctx.elementData.step,
      id: _ctx.elementData.id,
      class: _normalizeClass(["flex w-full border rounded bg-white px-4 py-1.5 text-black", { 'pr-12': _ctx.elementData.appendix }]),
      placeholder: _ctx.$t(_ctx.elementData?.placeholder ?? 'general.type_here'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formInputValueUpdate && _ctx.formInputValueUpdate(...args))),
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.formInputValueUpdate && _ctx.formInputValueUpdate(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelDynamic, _ctx.value]
    ]),
    (_ctx.elementData.appendix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.elementData.appendix)), 1))
      : _createCommentVNode("", true)
  ]))
}