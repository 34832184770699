import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full items-center"
}
const _hoisted_2 = { class: "w-full text-center text-red-500 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["mb-5 md:px-4", `w-${_ctx.elementData.width ?? 'full'} ${
      _ctx.elementData.width || _ctx.elementData.width === 'full' ? '' : 'md:pr-12'
    }`])
  }, [
    (_ctx.elementData?.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["text-gray-6 text-xl", {
        'mb-5': !_ctx.checkError(_ctx.elementData, _ctx.initialState),
      }])
        }, _toDisplayString(_ctx.$t(_ctx.elementData.label)), 3))
      : _createCommentVNode("", true),
    (_ctx.checkError(_ctx.elementData, _ctx.initialState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translateErrors(
            _ctx.formErrorsMap(
              _ctx.elementData,
              _ctx.initialState.validation[_ctx.elementData.valuePath][0]
            )
          )), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementData.structure, (innerElementData) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formElementsMap(innerElementData.type)), {
        elementData: {
        ...innerElementData,
        id: _ctx.createId(_ctx.elementData, innerElementData),
        valuePath: _ctx.createValuePath(_ctx.elementData, innerElementData),
      },
        values: _ctx.values,
        initialState: _ctx.prepareInitialState(innerElementData, _ctx.initialState),
        key: innerElementData.id,
        onFormInputValueUpdate: _ctx.formInputValueUpdate
      }, null, 40, ["elementData", "values", "initialState", "onFormInputValueUpdate"]))
    }), 128))
  ], 2))
}