import FormRow from "@/components/form/containers/FormRow.vue";
import FormSection from "@/components/form/containers/FormSection.vue";
import FormInput from "@/components/form/inputs/FormInput.vue";
import TextInput from "@/components/form/inputs/TextInput.vue";
import NumberInput from "@/components/form/inputs/NumberInput.vue";
import SelectInput from "@/components/form/inputs/SelectInput.vue";
import ArrayInput from "@/components/form/inputs/ArrayInput.vue";
import { FormElementData } from "@/models/FormElementData";
import { FormElementState } from "@/models/FormElementState";
import _ from "lodash";
import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import MultiSelectInput from "@/components/form/inputs/MultiSelectInput.vue";
import { Errors } from "@/common/Tools";

export type emittedData = [string, Ref, string, string?];

export type Option = {
  id?: number;
  name?: string;
  full_name?: string;
};

export type Options = Option[] | undefined;

export type CodeOption = {
  id?: number;
  code: string;
  name: string;
};

export type CodeOptions = CodeOption[] | undefined;

export type SelectOption = {
  value: string | number;
  label: string;
};

export type RegularValue = number | string | boolean;

export type ArrayValue = string[];

export type PossibleValues = Values | Values[] | ArrayValue | RegularValue;

export type Values = {
  [key: string]: PossibleValues;
};

export const emptyValues = [undefined, null];

export type FormState = {
  generalError?: string;
  validation: Errors;
  value: Values;
};

export const formElementsMap = (type: string): unknown => {
  switch (type) {
    case "row":
      return FormRow;
    case "section":
      return FormSection;
    case "text":
    case "number":
    case "select":
    case "array":
      return FormInput;
  }
};

export const formInputsMap = (type: string): unknown => {
  switch (type) {
    case "text":
      return TextInput;
    case "number":
      return NumberInput;
    case "select":
      return SelectInput;
    case "multi-select":
      return MultiSelectInput;
    case "array":
      return ArrayInput;
  }
};

export const prepareInitialState = (
  elementData: FormElementData,
  state: FormElementState
): FormElementState => {
  if (_.includes(["text", "number", "select", "array"], elementData.type)) {
    const path = elementData?.valuePath ?? elementData.id;
    const value = _.get(state.value, path, null);
    const validation = _.get(state.validation, path, null);
    if (value !== null || validation !== null) {
      return {
        value,
        validation,
      };
    }
    return {};
  } else {
    return state;
  }
};

export const formErrorsMap = (
  elementData: FormElementData,
  errorMessage: string
): string[] => {
  const { t } = useI18n();
  const croppedId = elementData.id.split(".").pop()?.split("]").pop();
  switch (errorMessage) {
    case "is missing":
    case "must be a string":
    case "must be an array":
    case "must be filled":
    case "must be an integer":
      return [`validation.missing_${croppedId}`];
    default:
      return [
        t(elementData.label ?? "")?.replace(/(.*) \d+/, "$1"),
        errorMessage,
      ];
  }
};

export const translateErrors = (keys: string[]): string => {
  const { t } = useI18n();
  return _.reduce(
    keys,
    (result: string, value: string) => {
      result += ` ${t(value)}`;
      return result.trim();
    },
    ""
  ).trim();
};

export const checkError = (
  elementData: FormElementData,
  initialState: FormElementState
): unknown => {
  if (elementData.type === "array") {
    return _.isArray(initialState?.validation);
  }
  if (elementData.type === "section") {
    return _.get(initialState, `validation.${elementData.valuePath}[0]`, false);
  }
  return initialState?.validation;
};

export const getOldValues = (data: Values, fields?: string[]): Values =>
  _.cloneDeep(
    _.reduce(
      fields,
      (values, field) => {
        _.set(values, field, _.get(data, field, null));
        return values;
      },
      {}
    )
  ) as Values;

export const valuesChanged = (
  newValues: Values,
  oldValues: Values,
  fields?: string[]
): boolean =>
  !_.every(fields, (field) => {
    const newValue = _.get(newValues, field, null);
    const oldValue = _.get(oldValues, field, null);
    return _.eq(
      JSON.parse(JSON.stringify(newValue)),
      JSON.parse(JSON.stringify(oldValue))
    );
  });

export const clearData = (data: Values): Values =>
  _.transform(
    data,
    (newData: Values, value: PossibleValues, key) => {
      if (_.isPlainObject(value)) {
        _.set(newData, key, clearData(value as Values));
      } else if (_.isArray(value)) {
        _.set(
          newData,
          key,
          _.without(
            _.map(value, (value) => {
              if (_.isPlainObject(value)) {
                return clearData(value as Values);
              } else {
                return value;
              }
            }),
            ...emptyValues
          )
        );
      } else {
        if (!_.includes(emptyValues, value as unknown)) {
          _.set(newData, key, _.identity(value));
        }
      }
    },
    {} as Values
  );

export const createId = (
  elementData: FormElementData,
  innerElementData: FormElementData,
  index?: number
): string => {
  let id = elementData.id;
  if (index) {
    id = id + `[${index}]`;
  } else {
    id = id + ".";
  }
  id = id + innerElementData.id;
  return id;
};

export const createValuePath = (
  elementData: FormElementData,
  innerElementData: FormElementData,
  index?: number
): string => {
  const valuePath = innerElementData.valuePath ?? innerElementData.id;
  if (_.isNumber(index)) {
    const parentContainer = _.includes(["row", "section"], elementData.type);
    const innerContainer = _.includes(
      ["row", "section"],
      innerElementData.type
    );
    let complexValuePath = elementData.valuePath ?? elementData.id;
    if (!parentContainer) {
      complexValuePath = `${complexValuePath}[${index}]`;
    }
    if (!innerContainer) {
      complexValuePath = `${complexValuePath}.${valuePath}`;
    }
    return complexValuePath;
  }
  return valuePath;
};

export const prepareClass = (
  elementData: FormElementData,
  initialState: FormElementState
): string => {
  let preparedClass = "flex flex-col md:items-center pr-6";
  if (elementData.class) {
    return elementData.class;
  } else {
    if (elementData?.width) {
      preparedClass += ` w-${elementData?.width}`;
    } else {
      preparedClass += " w-full";
    }
    if (!checkError(elementData, initialState)) {
      preparedClass += " mb-6";
    }
  }
  return preparedClass;
};

export const prepareOptionsStringId = (options: Options): SelectOption[] =>
  _.map(options, (option: Option) => ({
    value: option?.id?.toString() ?? option?.name ?? "Empty",
    label: option?.name ?? option?.full_name ?? "Empty",
  }));

export const prepareOptionsId = (options: Options): SelectOption[] =>
  _.map(options, (option: Option) => ({
    value: option?.id ?? option?.name ?? "Empty",
    label: option?.name ?? option?.full_name ?? "Empty",
  }));

export const prepareOptionsName = (options: Options): SelectOption[] =>
  _.map(options, (option: Option) => ({
    value: option.name ?? option.full_name ?? "Empty",
    label: option.name ?? option.full_name ?? "Empty",
  }));

export const prepareCodes = (options: CodeOptions): SelectOption[] =>
  _.map(options, (option: CodeOption) => ({
    value: option.code,
    label: option.name,
  }));
