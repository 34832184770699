import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "my-auto" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row w-full"
}
const _hoisted_5 = {
  key: 0,
  class: "flex w-28"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: `${_ctx.elementData.id}-container`,
    class: _normalizeClass(_ctx.prepareClass(_ctx.elementData, _ctx.initialState))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex-row w-full", {
        'flex md:flex-row': _ctx.elementData.type !== 'array',
        'flex flex-col': _ctx.elementData.type === 'array',
      }])
    }, [
      (_ctx.elementData.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            id: `${_ctx.elementData.id}-label`,
            class: _normalizeClass(["flex text-black mb-2 pt-2 md:mb-0", {
          'w-full': _ctx.elementData.type === 'array',
          'w-28 md:min-w-28': _ctx.elementData.type !== 'array',
        }])
          }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.elementData.label) +
            (_ctx.elementData?.required
              ? _ctx.elementData.required(
                  _ctx.values,
                  _ctx.elementData.valuePath ?? _ctx.elementData.id
                )
                ? "*"
                : ""
              : "")), 1)
          ], 10, _hoisted_2))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formInputsMap(_ctx.elementData.type)), {
        class: _normalizeClass({
          'flex flex-1': _ctx.elementData.type !== 'array',
        }),
        elementData: _ctx.elementData,
        values: _ctx.values,
        initialState: _ctx.initialState,
        key: _ctx.elementData.id,
        onFormInputValueUpdate: _ctx.formInputValueUpdate
      }, null, 40, ["class", "elementData", "values", "initialState", "onFormInputValueUpdate"]))
    ], 2),
    (_ctx.checkError(_ctx.elementData, _ctx.initialState))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.elementData?.type !== 'array')
            ? (_openBlock(), _createElementBlock("p", _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: _normalizeClass(["flex-1 text-center text-red-500 font-semibold text-center", `${_ctx.elementData?.type === 'array' ? 'mb-4' : 'md:pt-1 md:mb-0'}`])
          }, _toDisplayString(_ctx.translateErrors(
            _ctx.formErrorsMap(_ctx.elementData, _ctx.initialState?.validation[0])
          )), 3)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}