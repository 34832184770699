
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
} from "vue";
import _ from "lodash";
import { FormElementData } from "@/models/FormElementData";
import { FormElementState } from "@/models/FormElementState";
import {
  getOldValues,
  SelectOption,
  Values,
  valuesChanged,
} from "@/common/FormTools";

type MultiSelectState = {
  oldValues: Values;
  value: string[];
  options: SelectOption[];
  lastOptionsUpdate: number;
  isExpanded: boolean;
};

type MultiSelectMethods = {
  addValue: (option: SelectOption) => void;
  removeValue: (index: number) => void;
  expandActionList: () => void;
  formInputValueUpdate: () => void;
  loadOptions: (timestamp: number) => void;
  checkValueValidity: () => void;
  multiSelectWatcher: (newValues: Values) => void;
  valueWatcher: (newValue: string[]) => void;
  optionsWatcher: () => void;
};

type MultiSelectComputed = {
  availableOptions: ComputedRef<SelectOption[]>;
  selectedLabels: ComputedRef<string[]>;
  emptyValue: ComputedRef<boolean>;
  disabled: ComputedRef<boolean>;
};

export default defineComponent({
  name: "MultiSelectInput",
  emits: ["formInputValueUpdate"],
  props: {
    elementData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    values: {
      type: Object as PropType<Values>,
      default: () => ({}),
    },
    initialState: {
      type: Object as PropType<FormElementState>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const methods = {} as MultiSelectMethods;
    const state = ref<MultiSelectState>({
      oldValues: getOldValues(props.values, props.elementData.affectedBy),
      value: props.initialState.value ?? [],
      options: [],
      lastOptionsUpdate: new Date().getTime(),
      isExpanded: false,
    });
    const computedValues = ref<MultiSelectComputed>({
      availableOptions: computed<SelectOption[]>(
        () =>
          _.omitBy(state.value.options, (option) =>
            _.includes(state.value.value, option.value)
          ) as SelectOption[]
      ),
      selectedLabels: computed<string[]>(() =>
        _.map(
          state.value.value,
          (value) =>
            _.get(
              _.find(state.value.options, { value }),
              "label",
              "Empty Label"
            ).split(",")[0]
        )
      ),
      emptyValue: computed<boolean>(() => _.isEmpty(state.value.value)),
      disabled: computed<boolean>(() => !state.value.options.length),
    });

    methods.addValue = (option: SelectOption) => {
      state.value.value.push(option.value.toString());
      methods.formInputValueUpdate();
    };

    methods.removeValue = (index: number) => {
      _.pullAt(state.value.value, [index]);
      methods.formInputValueUpdate();
    };

    methods.expandActionList = () => {
      if (!computedValues.value.disabled) {
        state.value.isExpanded = !state.value.isExpanded;
      }
    };

    methods.formInputValueUpdate = () => {
      context.emit(
        "formInputValueUpdate",
        props.elementData.id,
        state.value.value,
        props.elementData.valuePath ?? props.elementData.id,
        props.elementData.index
      );
    };

    methods.loadOptions = async (timestamp: number) => {
      if (props.elementData.options) {
        const result = await props.elementData.options(
          props.values,
          props.elementData.valuePath ?? props.elementData.id
        );
        if (timestamp === state.value.lastOptionsUpdate) {
          state.value.options = result;
        }
      } else {
        state.value.options = [];
      }
      methods.checkValueValidity();
    };

    methods.checkValueValidity = () => {
      if (
        !_.isArray(state.value.value) ||
        (!_.isEmpty(state.value.value) &&
          !_.isEmpty(
            _.difference(state.value.value, _.map(state.value.options, "value"))
          ))
      ) {
        state.value.value = [];
        methods.formInputValueUpdate();
      }
    };

    methods.multiSelectWatcher = async (newValues: Values) => {
      if (
        valuesChanged(
          newValues,
          state.value.oldValues,
          props.elementData.affectedBy
        )
      ) {
        state.value.oldValues = getOldValues(
          newValues,
          props.elementData.affectedBy
        );
        const timestamp = new Date().getTime();
        state.value.lastOptionsUpdate = timestamp;
        await methods.loadOptions(timestamp);
      }
    };

    methods.valueWatcher = (newValue: string[]) => {
      if (state.value.value !== newValue) {
        state.value.value = newValue;
        const timestamp = new Date().getTime();
        state.value.lastOptionsUpdate = timestamp;
        methods.loadOptions(timestamp);
      }
    };

    methods.optionsWatcher = () => {
      const timestamp = new Date().getTime();
      state.value.lastOptionsUpdate = timestamp;
      methods.loadOptions(timestamp);
    };

    watch(() => props.values, methods.multiSelectWatcher, {
      deep: true,
    });

    watch(() => props.initialState.value, methods.valueWatcher, {
      deep: true,
    });

    watch(() => props.elementData.options, methods.optionsWatcher, {
      deep: true,
    });

    methods.loadOptions(state.value.lastOptionsUpdate);

    return {
      state,
      computedValues,
      methods,
    };
  },
});
