
import { defineComponent, PropType, ref } from "vue";
import { FormElementData } from "@/models/FormElementData";
import { FormElementState } from "@/models/FormElementState";

export default defineComponent({
  name: "TextInput",
  emits: ["formInputValueUpdate"],
  props: {
    elementData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    initialState: {
      type: Object as PropType<FormElementState>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const value = ref(props.initialState.value ?? "");
    const formInputValueUpdate = () => {
      context.emit(
        "formInputValueUpdate",
        props.elementData.id,
        value,
        props.elementData.valuePath ?? props.elementData.id,
        props.elementData.index
      );
    };
    return {
      formInputValueUpdate,
      value,
    };
  },
});
