import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md:flex flex-row", `w-${_ctx.elementData.width ?? 'full'}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementData.structure, (innerElementData) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.formElementsMap(innerElementData.type)), {
        elementData: {
        ...innerElementData,
        id: _ctx.createId(_ctx.elementData, innerElementData, _ctx.elementData?.index),
        valuePath: _ctx.createValuePath(
          _ctx.elementData,
          innerElementData,
          _ctx.elementData?.index
        ),
        index: _ctx.elementData?.index,
      },
        values: _ctx.values,
        initialState: _ctx.prepareInitialState(innerElementData, _ctx.initialState),
        key: innerElementData.id,
        onFormInputValueUpdate: _ctx.formInputValueUpdate
      }, null, 40, ["elementData", "values", "initialState", "onFormInputValueUpdate"]))
    }), 128))
  ], 2))
}