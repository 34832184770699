
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
  WatchCallback,
} from "vue";
import _ from "lodash";
import { FormElementData } from "@/models/FormElementData";
import { FormElementState } from "@/models/FormElementState";
import {
  getOldValues,
  SelectOption,
  Values,
  valuesChanged,
} from "@/common/FormTools";

type SelectState = {
  oldValues: Values;
  value: string;
  options: SelectOption[];
  lastOptionsUpdate: number;
};

type SelectMethods = {
  formInputValueUpdate: () => void;
  loadOptions: (timestamp: number) => void;
  checkValueValidity: () => void;
  selectWatcher: (newValues: Values) => void;
  newValueWatcher: (newValue: string) => void;
  optionsWatcher: () => void;
};

type SelectComputed = {
  emptyValue: ComputedRef<boolean>;
  disabled: ComputedRef<boolean>;
};

export default defineComponent({
  name: "SelectInput",
  emits: ["formInputValueUpdate"],
  props: {
    elementData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    values: {
      type: Object as PropType<Values>,
      default: () => ({}),
    },
    initialState: {
      type: Object as PropType<FormElementState>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const elementData = ref<FormElementData>(props.elementData);
    const values = ref<Values>(props.values);
    const initialState = ref<FormElementState>(props.initialState);
    const methods = {} as SelectMethods;
    const state = ref<SelectState>({
      oldValues: getOldValues(values.value, elementData.value.affectedBy),
      value: initialState.value.value ?? "",
      options: [],
      lastOptionsUpdate: new Date().getTime(),
    });
    const computedValues = ref<SelectComputed>({
      emptyValue: computed<boolean>(() => state.value.value !== ""),
      disabled: computed<boolean>(
        () =>
          !state.value.options.length || !!elementData.value.disabled?.(values)
      ),
    });

    methods.formInputValueUpdate = () => {
      context.emit(
        "formInputValueUpdate",
        elementData.value.id,
        state.value.value,
        elementData.value.valuePath ?? elementData.value.id,
        elementData.value.index
      );
    };

    methods.loadOptions = async (timestamp: number) => {
      if (elementData.value.options) {
        const result = await elementData.value.options(
          values.value,
          elementData.value.valuePath ?? elementData.value.id
        );
        if (timestamp === state.value.lastOptionsUpdate) {
          state.value.options = result;
          methods.checkValueValidity();
        }
      } else {
        state.value.options = [];
        methods.checkValueValidity();
      }
    };

    methods.checkValueValidity = () => {
      if (state.value.value) {
        if (
          !_.includes(_.map(state.value.options, "value"), state.value.value)
        ) {
          if (
            elementData.value.disabledEmptyOption &&
            state.value.options.length
          ) {
            state.value.value = state.value.options[0].value.toString();
          } else {
            state.value.value = "";
          }
        }
      } else {
        if (
          elementData.value.disabledEmptyOption &&
          state.value.options.length
        ) {
          state.value.value = state.value.options[0].value.toString();
          methods.formInputValueUpdate();
        }
      }
    };

    methods.selectWatcher = async (newValues: Values) => {
      if (
        valuesChanged(
          newValues,
          state.value.oldValues,
          elementData.value.affectedBy
        )
      ) {
        state.value.oldValues = getOldValues(
          newValues,
          elementData.value.affectedBy
        );
        const timestamp = new Date().getTime();
        state.value.lastOptionsUpdate = timestamp;
        await methods.loadOptions(timestamp);
      }
    };

    methods.newValueWatcher = (newValue: string) => {
      if (newValue !== state.value.value) {
        state.value.value = newValue;
        const timestamp = new Date().getTime();
        state.value.lastOptionsUpdate = timestamp;
        methods.loadOptions(timestamp);
      }
    };

    watch(() => values.value, methods.selectWatcher as WatchCallback, {
      deep: true,
    });

    watch(() => props.initialState.value, methods.newValueWatcher, {
      deep: true,
    });

    methods.loadOptions(state.value.lastOptionsUpdate);

    return {
      state,
      computedValues,
      methods,
    };
  },
});
