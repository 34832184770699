import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex relative h-12 w-full md:pt-2" }
const _hoisted_2 = ["id", "disabled"]
const _hoisted_3 = {
  key: 0,
  value: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      id: _ctx.elementData.id,
      class: _normalizeClass(["flex w-full border rounded bg-white px-4 py-1.5", {
        'text-black': _ctx.computedValues.emptyValue,
        // 'text-gray-6': !computedValues.emptyValue, // Disabled as it made dropdown pickup points gray
        'bg-gray-200': _ctx.computedValues.disabled,
      }]),
      disabled: _ctx.computedValues.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.methods.formInputValueUpdate && _ctx.methods.formInputValueUpdate(...args)))
    }, [
      (!_ctx.elementData.disabledEmptyOption)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.elementData?.placeholder ?? "general.select_placeholder")), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option.value,
          key: index
        }, _toDisplayString(option.label), 9, _hoisted_4))
      }), 128))
    ], 42, _hoisted_2), [
      [_vModelSelect, _ctx.state.value]
    ])
  ]))
}