import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex relative h-12 w-full md:pt-2" }
const _hoisted_2 = ["type", "id", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.elementData.type,
      id: _ctx.elementData.id,
      class: "flex w-full border rounded bg-white px-4 py-1.5 text-black",
      placeholder: _ctx.$t(_ctx.elementData.placeholder ?? 'general.type_here'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formInputValueUpdate && _ctx.formInputValueUpdate(...args))),
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.formInputValueUpdate && _ctx.formInputValueUpdate(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.value]
    ])
  ]))
}