
import { defineComponent, PropType } from "vue";
import { FormElementData } from "@/models/FormElementData.ts";
import {
  formElementsMap,
  prepareInitialState,
  createId,
  createValuePath,
} from "@/common/FormTools";
import { FormElementState } from "@/models/FormElementState";
import { Values } from "@/common/FormTools";

export default defineComponent({
  name: "FormRow",
  emits: ["formInputValueUpdate"],
  props: {
    elementData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    values: {
      type: Object as PropType<Values>,
      default: () => ({}),
    },
    initialState: {
      type: Object as PropType<FormElementState>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const formInputValueUpdate = (...args: unknown[]) => {
      context.emit("formInputValueUpdate", ...args);
    };
    return {
      formInputValueUpdate,
      formElementsMap,
      prepareInitialState,
      createId,
      createValuePath,
    };
  },
});
