
import { defineComponent, PropType, ref } from "vue";
import { FormElementData } from "@/models/FormElementData";
import {
  Values,
  formElementsMap,
  prepareInitialState,
  createId,
  createValuePath,
} from "@/common/FormTools";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import { FormElementState } from "@/models/FormElementState";
import _ from "lodash";

export default defineComponent({
  name: "ArrayInput",
  components: { DefaultButton },
  emits: ["formInputValueUpdate"],
  props: {
    elementData: {
      type: Object as PropType<FormElementData>,
      required: true,
    },
    values: {
      type: Object as PropType<Values>,
      default: () => ({}),
    },
    initialState: {
      type: Object as PropType<FormElementState>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const value = ref(props.initialState.value ?? []);
    let lastChanged = ref(new Date().getTime());
    const addSection = () => {
      value.value.push({});
      lastChanged.value = new Date().getTime();
      context.emit(
        "formInputValueUpdate",
        props.elementData.id,
        value.value,
        props.elementData.valuePath ?? props.elementData.id,
        props.elementData.index
      );
    };
    const removeSection = (index: number) => {
      value.value.splice(index, 1);
      lastChanged.value = new Date().getTime();
      context.emit(
        "formInputValueUpdate",
        props.elementData.id,
        value.value,
        props.elementData.valuePath ?? props.elementData.id,
        props.elementData.index
      );
    };
    const formInputValueUpdate = (
      inputId: string,
      value: unknown,
      valuePath: string,
      index: string
    ) => {
      const newId = `${props.elementData.id}[${index}].${inputId}`;
      const newPath = `${
        props.elementData.valuePath ?? props.elementData.id
      }[${index}].${inputId}`;
      context.emit(
        "formInputValueUpdate",
        newId,
        value,
        _.isEmpty(valuePath) ? newPath : valuePath,
        props.elementData.index
      );
    };
    const prepareSectionState = (
      index: number,
      sectionValues: unknown,
      validation: unknown
    ) => {
      const sectionValidation = _.get(validation, `[${index}]`, null);
      return {
        value: sectionValues,
        validation: sectionValidation,
      };
    };
    return {
      addSection,
      removeSection,
      formInputValueUpdate,
      prepareSectionState,
      formElementsMap,
      prepareInitialState,
      createId,
      createValuePath,
      value,
      lastChanged,
    };
  },
});
